import React from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import { Button, Card } from 'react-bootstrap'

export default function NewsList() {
  return (
    <StaticQuery
      query={graphql`
            query NewsQuery {
                  allMdx(sort: {order: DESC, fields: frontmatter___date}) {
                    edges {
                        node {
                        id
                        slug
                        excerpt
                        frontmatter {
                            date(formatString: "MMMM YYYY")
                            title
                          }
                        }
                    }
                }
            }
          `}
      render={data => (
        <div className='mt-4'>
          {data.allMdx.edges.map(ev => (
            <div key={ev.node.id}>
              <Card className='mb-4'>
                <Card.Body>
                  <Card.Title>{ev.node.frontmatter.title}</Card.Title>
                  <p>{ev.node.frontmatter.date}</p>
                  <Card.Subtitle className="mb-5 text-muted">{ev.node.excerpt}</Card.Subtitle>
                  <Button size="lg" variant="warning" ><Link style={{ textDecoration: 'none' }} to={`/${ev.node.slug}`}>Read More</Link></Button>
                </Card.Body>
              </Card>
            </div>
          ))
          }
        </div>
      )}
    />
  )
}
